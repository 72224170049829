﻿:root {
    --organigram-background-color: rgb(236,210,144);
    --organigram-width: 200px;
    --organigram-height: 85px;
    --organigram-contour-link: rgb(127, 96, 0);
}

.organigram-node-button-foreign-object {
    cursor: pointer;
}

.organigram-card-contour {
    font-family: 'Inter', sans-serif;
    background-color: var(--organigram-background-color);
    position: absolute;
    margin-top: -1px;
    margin-left: -1px;
    width: var(--organigram-width);
    height: var(--organigram-height);
    border-radius: 10px;
    border: 1px solid var(--organigram-contour-link);
}

.organigram-child-number {
    color: #716E7B;
    border-radius: 5px;
    padding: 4px;
    font-size: 10px;
    margin: auto auto;
    background-color: white;
    border: 1px solid #E4E2E9;
}

.organigram-arrow-style {
    font-size: 9px;
}

.organigram-card {
    background-color: var(--organigram-color);
    position: absolute;
    margin-top: -20px;
    margin-left: 18px;
    border-radius: 100px;
    width: 45px;
    height: 45px;
}

.organigram-card-image {
    position: absolute;
    margin-top: -20px;
    margin-left: 18px;
    border-radius: 100px;
    width: 45px;
    height: 45px;
}

.organigram-detail-link {
    color: black;
}

.organigram-content-text {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
}

.organigram-card-plus {
    color: #08011E;
    position: absolute;
    right: 20px;
    top: 17px;
    font-size: 10px;
}

.organigram-card-text {
    font-size: 16px;
    color: #08011E;
    margin-left: 10px;
    margin-top: 8px;
    margin-right: 10px;
}

.organigram-card-text-no-image {
    font-size: 16px;
    color: #08011E;
    margin-left: 10px;
    margin-right: 10px;
}

.organigram-card-text-second {
    color: #716E7B;
    margin-left: 10px;
    margin-top: 3px;
    font-size: 14px;
    margin-right: 10px;
}

path.link {
    stroke: var(--organigram-contour-link);
    stroke-width: 2px;
}
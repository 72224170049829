﻿@each $breakpoint, $value in $grid-breakpoints {
    $infix: if($breakpoint != 'xs', '-#{$breakpoint}', '');

    @media (min-width: $value) {
        :root {
            --current-screen-width: #{$breakpoint};
        }

        .align-items-center#{$infix} {
            align-items: center !important;
        }

        .smaller-font-min#{$infix} {
            font-size: smaller;
        }
    }

    @media (max-width: $value) {
        .smaller-font-max#{$infix} {
            font-size: smaller;
        }
    }
}

﻿.text-simpleterface {
    color: var(--simple-button-face-background-color);
}

.text-simpleterinformation {
    color: var(--simple-button-information-background-color);
}

.text-simpletersubmenu {
    color: var(--simple-button-submenu-background-color);
}

.text-simpletersubmit {
    color: var(--simple-button-submit-background-color);
}

.text-simpletersecondary {
    color: var(--simple-button-secondary-background-color);
}

﻿// Bootstrap overrided Styles
.popover {
    font-size: 0.7rem;
    box-shadow: 7px 5px 8px #d6d6d6;
}

.popover-body {
    background-color: var(--simple-background-color-lightblue);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.bs-popover-start > .popover-arrow:after {
    border-left-color: var(--simple-background-color-lightblue) !important;
}

.bs-popover-end > .popover-arrow:after {
    border-right-color: var(--simple-background-color-lightblue) !important;
}

.bs-popover-top > .popover-arrow:after {
    border-top-color: var(--simple-background-color-lightblue) !important;
}

.bs-popover-bottom > .popover-arrow:after {
    border-bottom-color: var(--simple-background-color-lightblue) !important;
}

﻿.simple-signature-pad {
    box-shadow: none !important;
    border-style: none !important;
    padding: 0 !important;
    border-radius: 5px !important;
}

.simple-signature-pad::after {
    box-shadow: none !important;
}

.simple-signature-pad::before {
    box-shadow: none !important;
}

.signature-pad--body {
    margin: 5px 5px 0px 5px !important;
}

.signature-pad--footer {
    margin-bottom: 5px !important;
}

.signature-pad--body canvas {
    background-color: var(--simple-input-background);
    border-radius: 5px !important;
}

.signature-pad--action-item {
    margin: 0 5px 5px 5px !important;
}

﻿input.basic:read-only,
input.basic:disabled {
    background-color: var(--simple-disabled-background);
}

input.bold-text {
    font-weight: 600;
}

input[type = 'search']::-webkit-search-cancel-button {
    background: url(Static/images/close.svg) no-repeat 50% 50%;
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
}

input.invalid {
    padding-right: calc(1.5em + 0.75rem) !important;
    background-image: url(Static/images/invalid.svg);
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

input[type='date'].empty {
    color: var(--simple-placeholder-color);
}

input[type='date'].empty:focus {
    color: var(--bs-body-color);
}

input[type='date'].empty:hover {
    color: var(--bs-body-color);
}

select.empty {
    color: var(--simple-placeholder-color);
}

select.empty:hover {
    color: var(--bs-body-color);
}

select.empty:focus {
    color: var(--bs-body-color);
}

.input-group.vertical {
    flex-direction: column !important;
}

.input-group.vertical {
    > :first-child {
        border-top-right-radius: 4px !important;
        border-bottom-left-radius: 0px !important;
    }

    > :not(:first-child):not(ul) {
        margin-top: -1px !important;
        margin-left: 0px !important;
        width: 100% !important;
    }

    > :not(:first-child):not(:last-child):not(ul) {
        border-radius: 0px !important;
    }

    > :last-child:not(ul) {
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 4px !important;
    }

    .btn {
        max-width: 100% !important;
    }
}

﻿$display-title: false;

.simple-head-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-left: 5px;
    font-weight: 500;
}

.simple-page-title {
    @if($display-title) {
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    @else {
        display: none;
    }
}

.simple-page-title > h1 {
    margin: 0;
}

@if($display-title) {
    @media screen and (min-width: 600px) {
        .simple-head-title {
            display: none;
        }
    }

    @media screen and (max-width:600px) {
        .simple-page-title {
            display: none;
        }
    }
}

﻿.btn {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 3px;
    margin-right: 3px;
    max-width: 300px;
}

.button-content {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

a.btn:visited {
    color: black;
}

.input-group > button {
    margin-right: 0px;
}

.btn-lg {
    padding: 5px;
    font-weight: 500;
    max-width: 300px;
}

.btn-label {
    min-width: 40px;
}

.btn-group > .btn {
    margin-bottom: 2px;
}

.simple-button {
    padding-left: 5px;
    padding-right: 5px;
    display: block;
    cursor: pointer;
}

.simple-button-inline {
    padding-left: 5px;
    padding-right: 5px;
}

.btn-simpleterface {
    background-color: var(--simple-button-face-background-color);
    border-color: var(--simple-button-face-border-color);
    color: var(--simple-button-face-color);
}

.btn-simpleterface:hover {
    filter: brightness(105%);
    transition-duration: 0.3s;
}

.btn-simpleterface:active {
    filter: brightness(110%);
    transition-duration: 0.1s;
}

.btn-simpleterface:focus {
    filter: brightness(110%);
    transition-duration: 0.1s;
}

.btn-simpleterinformation {
    background-color: var(--simple-button-information-background-color);
    border-color: var(--simple-button-information-border-color);
    color: var(--simple-button-information-color);
}

.btn-simpleterinformation:hover {
    filter: brightness(110%);
    transition-duration: 0.3s;
}

.btn-simpleterinformation:active {
    filter: brightness(120%);
    transition-duration: 0.1s;
}

.btn-simpleterinformation:focus {
    filter: brightness(120%);
    transition-duration: 0.1s;
}

.btn-simpletersubmenu {
    background-color: var(--simple-button-submenu-background-color);
    border-color: var(--simple-button-submenu-border-color);
    color: var(--simple-button-submenu-color);
}

.btn-simpletersubmenu:hover {
    filter: brightness(105%);
    transition-duration: 0.3s;
}

.btn-simpletersubmenu:active {
    filter: brightness(110%);
    transition-duration: 0.1s;
}

.btn-simpletersubmenu:focus {
    filter: brightness(110%);
    transition-duration: 0.1s;
}

.btn-simpletersubmit {
    background-color: var(--simple-button-submit-background-color);
    border-color: var(--simple-button-submit-border-color);
    color: var(--simple-button-submit-color);
}

.btn-simpletersubmit:hover {
    filter: brightness(105%);
    transition-duration: 0.3s;
}

.btn-simpletersubmit:active {
    filter: brightness(110%);
    transition-duration: 0.1s;
}

.btn-simpletersubmit:focus {
    filter: brightness(110%);
    transition-duration: 0.1s;
}

.btn-simpletersecondary {
    background-color: var(--simple-button-secondary-background-color);
    border-color: var(--simple-button-secondary-border-color);
    color: var(--simple-button-secondary-color);
}

.btn-simpletersecondary:hover {
    filter: brightness(105%);
    transition-duration: 0.3s;
}

.btn-simpletersecondary:active {
    filter: brightness(110%);
    transition-duration: 0.1s;
}

.btn-simpletersecondary:focus {
    filter: brightness(110%);
    transition-duration: 0.1s;
}

.btn-simpletermenu {
    background-color: var(--simple-button-menu-background-color);
    border-color: var(--simple-button-menu-border-color);
    color: var(--simple-button-menu-color);
}

.btn-simpletermenu:hover {
    filter: brightness(105%);
    transition-duration: 0.3s;
}

.btn-simpletermenu:active {
    filter: brightness(110%);
    transition-duration: 0.1s;
}

.btn-simpletermenu:focus {
    filter: brightness(110%);
    transition-duration: 0.1s;
}

.btn-simpleterbuttonpage {
    background-color: var(--simple-button-buttonpage-background-color);
    border-color: var(--simple-button-buttonpage-border-color);
    color: var(--simple-button-buttonpage-color);
}

.btn-simpleterbuttonpage:hover {
    filter: brightness(105%);
    transition-duration: 0.3s;
}

.btn-simpleterbuttonpage:active {
    filter: brightness(110%);
    transition-duration: 0.1s;
}

.btn-simpleterbuttonpage:focus {
    filter: brightness(110%);
    transition-duration: 0.1s;
}

.btn-simpletergradient {
    background-image: linear-gradient(90deg,#85CEFF,#1FA5FF);
    border: #0099FF;
    color: var(--simple-button-gradient-color);
    box-shadow: 2px 2px 3px 0px #bdb8b8;
}

.label-button {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 40px;
    max-width: 100px;
}

.label-button-icon {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    padding-top: 2px;
}

.label-button-inline {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
    padding-left: 3px;
}

.clickable {
    cursor: pointer;
}

.btn {
    padding: 0;
}

:not(.input-group) > .btn {
    margin-bottom: 1px;
}

.btn:hover {
    box-shadow: var(--simple-button-face-background-color) 0px 0px 2px 1px;
}

.btn-group > .btn {
    margin-bottom: 1px;
    margin-right: 3px;
}

.btn .button-container-content {
    height: 28px;
}

.btn .button-content {
    vertical-align: middle;
    padding: 0.3rem 0.4rem;
}

.btn.btn-lg .button-container-content {
    height: 32px;
}

.btn.btn-lg .button-content {
    padding: 4px 6px;
}

.btn.btn-sm .button-container-content {
    height: 23px;
}

.btn.btn-sm .button-content {
    padding: 2px 3px;
}

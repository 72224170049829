﻿.validation-errors {
    border-bottom-color: lightgrey;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.validation-message {
    color: #b10101;
}

.valid.modified:not([type=checkbox]) {
    border-bottom-style: solid;
    border-bottom-color: #26b050;
    border-width: 1px;
}

.invalid {
    border-bottom-style: solid;
    border-bottom-color: red;
    border-bottom-width: 1px;
}

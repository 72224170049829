// Custom SCSS Variables
@import 'Variables';

// External Dependencies
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i');
@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/@material-icons/font/scss/all.scss";

html {
    height: 100% !important;
}

body {
    font-family: #{$simple-font-face};
    font-size: #{$simple-body-font-size};
    height: 100% !important;
}

body > div[id=app] {
    height: 100% !important;
}


a:hover {
    transition: ease-in-out 0.2s;
}

a:visited {
    color: #{$simple-link-color};
}

.loading-background{
    display:none;
}

// Internal Simpleter Components and tools
@import 'Components/Buttons';
@import 'Components/Icons';
@import 'Components/PageTitle';
@import 'Components/NavLink';
@import 'Components/Popover';
@import 'Components/SignaturePad';
@import 'Components/Tables';
@import 'Components/Texts';
@import 'Components/Validations';
@import 'Components/Input';
@import 'Components/ProgressSpinner';
@import 'Components/Organigram';
@import 'Components/DisplayOrientation';
@import 'Components/NavigationBar';
@import 'Components/Scrollbar';

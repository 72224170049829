﻿a.simple-link-selected div {
    background-color: #add8e6d4;
    font-weight: 500;
}

a.simple-link {
    color: var(--bs-body-color);
    text-decoration: none;
}

a.simple-link :visited {
    color: var(--bs-body-color);
    text-decoration: none;
}

.icon-link {
    color: var(--bs-body-color);
}

.icon-link:visited {
    color: var(--bs-body-color);
}

.icon-link:hover {
    color: var(--bs-body-color);
}

﻿/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #b7b7b7 #F5F5F5;
}

/* Chrome, Edge and Safari */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-track:hover {
    background-color: #F0F0F0;
}

::-webkit-scrollbar-track:active {
    background-color: #F0F0F0;
}

::-webkit-scrollbar-thumb {
    background-color: #b7b7b7;
}

﻿// Simpleter Variables
$simple-primary: #46BEFF; // Utilisé comme couleur de thème primaire pour Bootstrap
$simple-main-color: #C4BD97; // Utilisé comme couleur de thème secondaire pour Bootstrap
$simple-dark-theme: #4D4A3B; // Utilisé pour les couleurs de police et le thème sombre
$simple-secondary: #EEECE1; // Utilisé comme couleur de thème clair
$simple-text-color-information: #887834; // couleur de texte dans les boites de dialogue
$simple-menu-width: 260px; // Largeur du menu dépliable
$simple-menu-border: #996633; // Couleur border menu
$simple-blue-simpleter: #0099ff; // Couleur du bleu du logo
$simple-link-color: #0093F5; // Couleur de lien
$simple-link-color-alt: #006EB8; // Couleur alternative de lien
$simple-body-background-color: #FFFFFF; //Couleur du body

// App Bar et Barre de Menu
$simple-menu-header-appbar-height: 25px; // hauteur de la AppBar
$simple-menu-header-menu-height: 40px; // hauteur de la barre de menu
$simple-menu-header-breadcrumb-height: 22px; // hauteur du fil d'ariane

$simple-menu-header-height-with-appbar: $simple-menu-header-menu-height+$simple-menu-header-appbar-height;
$simple-menu-header-height-with-appbar-and-breadcrumb: $simple-menu-header-height-with-appbar+$simple-menu-header-breadcrumb-height;

$simple-background-color-lightblue: rgb(222, 235, 247); // Couleur bleue de fond

// Tableaux et composants
$simple-table-header-background: #F1EDDB;
$simple-table-filter-background: #E4F2CA;
$simple-input-border-color: #adb5bd;
$simple-table-border: #E1D8B1;

// Thème de bouton personnalisé (Simpleter)
$simple-button-face-background-color: #E1D8B1;
$simple-button-face-border-color: #BFBDA0;
$simple-button-face-color: #1A1914;
$simple-button-information-background-color: #FFFFAB;
$simple-button-information-border-color: #BFBDA0;
$simple-button-information-color: #1A1914;
$simple-button-submenu-background-color: #FFEBCD;
$simple-button-submenu-border-color: #BFBDA0;
$simple-button-submenu-color: #1A1914;
$simple-button-submit-background-color: #9FE855;
$simple-button-submit-border-color: #BFBDA0;
$simple-button-submit-color: #1A1914;
$simple-button-secondary-background-color: #F1EDDB;
$simple-button-secondary-border-color: #BFBDA0;
$simple-button-secondary-color: #1A1914;
$simple-button-menu-background-color: #F9F8F0;
$simple-button-menu-border-color: #BFBDA0;
$simple-button-menu-color: #1A1914;
$simple-button-buttonpage-background-color: #FFE699;
$simple-button-buttonpage-border-color: #BFBDA0;
$simple-button-buttonpage-color: #1A1914;
$simple-button-gradient-color: #1A1914;
$simple-white-background: rgba(255,255,255,0.9);

// Polices
$simple-body-font-size: 0.8rem;
$simple-font-size: 0.7rem; // Taille de la police par défaut
$simple-font-face: Roboto, sans-serif; // Polices de l'application

// Couleurs de componsants Input
$simple-input-background: #FFFFE3; // Couleur de fond d'un champ éditable
$simple-focus-background: #FFFFF9; // Couleur de fond d'un champ sélectioné
$simple-disabled-background: #F6F6F6; // Couleur de fond d'un champ désactivé

// Bootstrap Main Variables
$primary: $simple-primary;
$secondary: $simple-main-color;
$dark: $simple-dark-theme;
$light: $simple-secondary;

// Bootstrap Colors
$black: #000000;
$blue: $simple-blue-simpleter;

$body-color: $dark; // Couleur du texte
$input-bg: $simple-input-background;
$input-group-addon-bg: $simple-secondary; // Couleur de fond des libellés dans les InputGroup
$input-placeholder-color: #D3DCDE; // Couleur de texte du placeholder TODO : Trouver une couleur adéquate
$input-border-color: $simple-input-border-color;
$input-focus-border-color: lighten($simple-input-border-color,10%);
$input-group-addon-border-color: $simple-input-border-color;
$card-border-color: rgba($black, .300);

// Boostrap Fonts
$font-size-base: $simple-font-size;
$h1-font-size: $font-size-base*1.4;
$h6-font-size: $font-size-base*1.1;

// Bootstrap Disabled Colors and Opacity
$btn-disabled-opacity: 0.3;

// Bootstrap Border Radius
$border-radius-lg: 0.3rem;
$border-radius: 0.25rem;
$border-radius-sm: 0.2rem;
$toast-border-radius: 0.25rem;
$input-border-radius: 0.25rem;
$badge-pill-border-radius: 0.3rem;
$nav-tabs-border-radius: 0.25rem;
$list-group-border-radius: 0.25rem;

// Modal customization
$modal-backdrop-bg: black;
$modal-content-border-radius: $border-radius-sm;
$modal-backdrop-opacity: 0.3;

// Bootstrap Behaviors
$enable-shadows: false;
$enable-transitions: true;
$enable-validation-icons: true;
$enable-deprecation-messages: false;
$enable-responsive-font-sizes: true;

// Bootstrap shadows
$box-shadow: 0 8px 6px -3px #AAA;
$input-btn-focus-box-shadow: 0 0 0 0;
$dropdown-box-shadow: 0 0 0 0;
$dropdown-min-width: 7rem;
$progress-box-shadow: 0 8px 6px -3px #AAA;

$input-btn-padding-y: 0.3rem; // Marge Y sur les boutons et zones d'input (et libélle input group)
$input-btn-padding-x: 0.4rem; // Marge X sur les boutons et zones d'input (et libélle input group)
$input-btn-focus-width: 2px;
$input-btn-padding-y-sm: 0.1rem;
$input-btn-padding-x-sm: 0.3rem;

// Links
$link-color: $simple-link-color;
$link-hover-color: $simple-link-color-alt;
$link-decoration: none;
$link-hover-decoration: underline;

// Bootstrap others variables
$input-bg: $simple-input-background;
$dropdown-link-hover-bg: $light;
$font-family-sans-serif: $simple-font-face;
$input-disabled-bg: $simple-disabled-background;
$card-cap-bg: white;
$line-height-base: 1.5;
$line-height-sm: $line-height-base;
$line-height-lg: $line-height-base;

// Material-Icons Variables
$font-path: "../Styles/Static/fonts/"; // Chemin relatif vers les fontes pour Material Icons Fonts

// CSS Published Variables
:root {
    --simple-link-color: #{$simple-link-color};
    --simple-link-color-alt: #{$simple-link-color-alt};
    --simple-container-border-color: #{$card-border-color};
    --simple-blue-simpleter: #{$simple-blue-simpleter};
    --simple-menu-header-appbar-height: #{$simple-menu-header-appbar-height};
    --simple-menu-header-height: #{$simple-menu-header-menu-height};
    --simple-menu-header-breadcrumb-height: #{$simple-menu-header-breadcrumb-height};
    --simple-menu-header-height-with-appbar: #{$simple-menu-header-height-with-appbar};
    --simple-menu-header-height-with-appbar-and-breadcrumb: #{$simple-menu-header-height-with-appbar-and-breadcrumb};
    --simple-body-font-size: #{$simple-body-font-size};
    --simple-background: #{$simple-secondary};
    --simple-foreground: #{$body-color};
    --simple-title-background: #{$simple-secondary};
    --simple-title-foreground: #{$body-color};
    --simple-sidebar-background: #{$simple-secondary};
    --simple-sidebar-foreground: #{$body-color};
    --simple-background-color-lightblue: #{$simple-background-color-lightblue};
    --simple-body-background-color: #{$simple-body-background-color};
    --simple-button-face-background-color: #{$simple-button-face-background-color};
    --simple-button-face-border-color: #{$simple-button-face-border-color};
    --simple-button-face-color: #{$simple-button-face-color};
    --simple-button-information-background-color: #{$simple-button-information-background-color};
    --simple-button-information-border-color: #{$simple-button-information-border-color};
    --simple-button-information-color: #{$simple-button-information-color};
    --simple-button-submenu-background-color: #{$simple-button-submenu-background-color};
    --simple-button-submenu-border-color: #{$simple-button-submenu-border-color};
    --simple-button-submenu-color: #{$simple-button-submenu-color};
    --simple-button-submit-background-color: #{$simple-button-submit-background-color};
    --simple-button-submit-border-color: #{$simple-button-submit-border-color};
    --simple-button-submit-color: #{$simple-button-submit-color};
    --simple-button-secondary-background-color: #{$simple-button-secondary-background-color};
    --simple-button-secondary-border-color: #{$simple-button-secondary-border-color};
    --simple-button-secondary-color: #{$simple-button-secondary-color};
    --simple-button-menu-background-color: #{$simple-button-menu-background-color};
    --simple-button-menu-border-color: #{$simple-button-menu-border-color};
    --simple-button-menu-color: #{$simple-button-menu-color};
    --simple-button-buttonpage-background-color: #{$simple-button-buttonpage-background-color};
    --simple-button-buttonpage-border-color: #{$simple-button-buttonpage-border-color};
    --simple-button-buttonpage-color: #{$simple-button-buttonpage-color};
    --simple-input-background: #{$simple-input-background};
    --simple-table-header-background: #{$simple-table-header-background};
    --simple-table-border: #{$simple-table-border};
    --simple-text-color-information: #{$simple-text-color-information};
    --simple-white-background: #{$simple-white-background};
    --simple-menu-width: #{$simple-menu-width};
    --simple-menu-border: #{$simple-menu-border};
    --simple-placeholder-color: #{$input-placeholder-color};
    --simple-disabled-background: #{$simple-disabled-background};
}

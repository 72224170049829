﻿$themeColor: #63B8FF;
$backColor: #ddd;
$textShadow: rgba(black, 0.35) 1px 1px 1px;

.progress--circle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: $backColor;

    &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 8px;
        width: 34px;
        height: 34px;
        border-radius: 50%;
        background-color: white;
    }

    &:after {
        content: '';
        display: inline-block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: $themeColor;
    }
}

.progress__number {
    position: absolute;
    top: 50%;
    width: 100%;
    line-height: 1;
    margin-top: -0.3rem;
    text-align: center;
    font-size: 0.8rem;
    color: #777;
    font-weight: bold;
}


$step: 1;
$loops: round(100 / $step);
$increment: 360 / $loops;
$half: round($loops / 2);

@for $i from 0 through $loops {
    .progress--bar.progress--#{$i * $step}:after {
        width: $i * $step * 1%;
    }

    .progress--circle.progress--#{$i * $step}:after {
        @if $i < $half {
            $nextDeg: 90deg + ($increment * $i);
            background-image: linear-gradient(90deg, $backColor 50%, transparent 50%, transparent), linear-gradient($nextDeg, $themeColor 50%, $backColor 50%, $backColor);
        }
        @else {
            $nextDeg: -90deg + ($increment * ($i - $half));
            background-image: linear-gradient($nextDeg, $themeColor 50%, transparent 50%, transparent), linear-gradient(270deg, $themeColor 50%, $backColor 50%, $backColor);
        }
    }
}
